import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import '../css/kpi.scss'
import { Navbar } from '../components/navbar'
import { Link } from 'gatsby'
import KpiIntroImage from '../images/kpi-intro-img.svg'
import ThreeCircles from '../images/three-circles.png'
import AngleDown from '../images/angle-down.png'
import KpiAboutImage from '../images/about-kpi-img.svg'
import InfoImage from '../images/info-img.svg'
import ThreeArrows from '../images/three-arrows.png'
import ProgramGoalsImage from '../images/program-goals.svg'

const keywords =
	'Okrs, meaning of kpis, okr example, okrs meaning, what okr, okr o, metrics kpis, measurewhatmatters, whats kpi, kpis define, key indicators performance, example kpis, examples of organizational structures, okrs tool, track okrs, okrs software, okrs goals, okr objectives and key results, objectives and key results, objective key results, okrs system, okrs framework, objectives and key results examples, defining okrs, okr plan, okrs definition, okr kpis, objective key results adalah, okrs methodology, what okr, kpi dashboard, objectives and key results examples, okr marketing, okr planning, okr software, okrs goals, okr system, okr tracking, okrs stands for, okr meaning business, okr tool, okr templates, okrs kpis'

// rebuild

const list = [
	{
		title: 'Focus',
		description:
			'OKRs focus on just 3-5 goals per quarter. This forces companies to make hard choices to eliminate tasks that don’t matter, create value and improve user experience.',
	},
	{
		title: 'Alignment',
		description:
			'OKRs focus on just 3-5 goals per quarter. This forces companies to make hard choices to eliminate tasks that don’t matter, create value and improve user experience.',
	},
	{
		title: 'Tracking',
		description:
			'Data-driven OKRs are the new way to set goals. Gone are the days when managers and employees could only set an annual performance goal. Data is everywhere, and leaders in agile organizations know how to use it to define their best strategies.',
	},
	{
		title: 'Transparency',
		description:
			'At OKRs, everyone can see the goals and progress at any level of the company. You can update your own goals, see progress in the form of progress bars, and easily find goals that match your interests.',
	},
	{
		title: 'Stretch',
		description:
			'Great companies use stretch goals to push their teams to achieve what might seem impossible — and receive support when things fall short.',
	},
]

const levels = [
	{
		title: 'the company level, top level company-wide OKRs',
		description:
			'This is the person responsible for spearheading the adoption of the OKR framework within an organization, communicating its value, and driving progress from the top.',
	},
	{
		title: 'the department OKRs',
		description:
			'This is the person responsible for spearheading the adoption of the OKR framework within an organization, communicating its value, and driving progress from the top.',
	},
	{
		title: 'The team level, individual employee OKRs',
		description:
			'These are the individuals leading teams -- from C-level executives and senior leadership to directors and department managers -- that generally own Objectives and goals for the company.',
	},
	{
		title: 'The individual  OKRs',
		description:
			'These are individual employees within a company; sometimes they have direct ownership of Key Results, other times they are simply observers to organization goals.',
	},
]

const goals = [
	'(January / February / March)',
	'(April / May / June)',
	'(July / August / September)',
	'(October / November / December)',
]

const linkProps = {
	to: '/trial',
	state: { productName: 'Employees Productivity' },
	onClick: () => {
		localStorage.setItem('state', JSON.stringify({ planName: 'Employees Productivity' }))
	},
}

const IndexPage = ({ data }) => {
	return (
		<Fragment>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>
			<Layout>
				{/* <SEO
                title="OKRs Software"
                meta={[
                    {
                        name: "keywords",
                        content: keywords
                    }                    
                ]}
            /> */}
				<div className="kpi-page">
					<Navbar />
					<div className="kpi-wrapper">
						<div className="intro-section section">
							<div className="container grid-col-2">
								<div className="intro-section-content">
									<h2 className="intro-section-title kpi-title">
										OKR software that helps teams outperform their
										competitors.
										<img src={ThreeCircles} alt="bg image" />
									</h2>

									<p className="intro-section-description kpi-description">
										The new Analytics and OKR integrated platform
										helps you manage your strategy, people and
										performance all in one place.
									</p>

									<div className="intro-section-links">
										<Link
											{...linkProps}
											className="kpi-gradient-link kpi-link"
										>
											Start for free
										</Link>

										<a
											href="https://app.ilpapps.com"
											target="_blank"
											className="kpi-normal-link kpi-link link-outlined"
										>
											Already Registered
										</a>
									</div>
								</div>

								<div className="intro-section-image">
									<img src={KpiIntroImage} alt="Kpi intro image" />
								</div>
							</div>
						</div>

						<div className="about-section section">
							<div className="container grid-col-2">
								<div className="about-section-content">
									<h2 className="kpi-title">
										What <br /> are OKRs?
									</h2>

									<p
										className="kpi-description"
										style={{ marginBottom: '32px' }}
									>
										OKR is a goal management system that requires
										regular check-ins, feedback, continuous learning,
										collaboration and problem-solving. With OKR you
										can manage your team’s overall progress on
										strategic priorities.
									</p>

									<p className="kpi-description">
										Objectives and Key Results (OKRs) are a simple yet
										powerful tool for any organization to execute its
										strategy with focus and alignment. They are useful
										for startups, scale-ups, or large corporations
										with thousands of employees—anyone who sets goals
										that need to be measured against objectives is a
										candidate for using this system of goal setting.
									</p>
								</div>

								<div className="">
									<img src={KpiAboutImage} alt="Kpi about image" />
								</div>
							</div>
						</div>

						<div className="info-section">
							<div
								className="container info-section-container"
								style={{ maxWidth: '1100px' }}
							>
								<div className="info-section-img">
									<img src={InfoImage} alt="Info image" />
								</div>

								<div className="info-section-content">
									<h2 className="kpi-title">
										Where <br />
										do OKRs come from?
									</h2>

									<p className="kpi-description">
										OKRs were developed by Andy Grove, the legendary
										CEO of Intel, who believed that all companies
										should use them. Today, OKRs are used every day at
										all kinds of organizations across the world
										including Google, Facebook, Twitter and over
										one-third of Fortune 500 companies.
									</p>
								</div>
							</div>
						</div>

						<div className="services-section">
							<div className="container" style={{ maxWidth: '1050px' }}>
								<h2 className="kpi-title">
									Why <br />
									are OKRs important for business?
								</h2>

								<p className="kpi-description">
									OKRs are a set of goals set by the employee, which are
									agreed upon by the manager, in teams, to achieve business
									objectives and improve performance.
								</p>

								<div className="services-notes">
									<div className="services-notes-header">
										<div className="services-notes-logo">
											<img
												src={ThreeArrows}
												alt="three arrows"
											/>
										</div>

										<p
											style={{ maxWidth: '300px' }}
											className="kpi-description"
										>
											Implement Your strategy with OKRs:
										</p>
									</div>

									<div className="services-notes-description kpi-description">
										Organizations are making use of OKR programs to
										communicate the strategy in the most effective way
										possible. This results in higher employee
										satisfaction and productivity, higher performance
										and ultimately better outcome for the business
									</div>
								</div>

								<div className="kpi-superpowers">
									<div className="kpi-superpowers-title">
										<h3 className="kpi-title">
											OKRs <br /> 5 Superpowers
										</h3>

										<div className="kpi-superpowers-logo">
											<img
												src={ThreeArrows}
												alt="three arrows"
											/>
											<span>Start for free</span>
										</div>
									</div>

									<div className="kpi-superpowers-list">
										{list.map(({ title, description }, index) => (
											<div
												className="kpi-superpowers-list-item"
												key={index}
											>
												<div
													className="item-index"
													style={{
														margin: '10px 0 0',
													}}
												>
													0{index + 1}/
												</div>

												<div className="item-content">
													<div className="item-title">
														{title}
													</div>
													<div className="item-description">
														{description}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="okr-levels-section">
								<div
									className="okr-levels-container container"
									style={{ maxWidth: '1100px' }}
								>
									<h2 className="kpi-title">
										OKR <br />
										Levels
									</h2>

									<p
										className="kpi-description"
										style={{ margin: '0 0 16px' }}
									>
										There are four levels in each organization.
										Everyone can see their OKRs and get inspired by
										what others are doing. You can set your personal
										OKRs and align your objectives with the overall
										goals of the company.
									</p>

									<div className="levels-list">
										{levels.map((level, index) => (
											<div
												className="level-list-item"
												key={index}
											>
												<div className="level-item-index">
													0{index + 1}/
												</div>

												<div className="level-item-content">
													<div className="level-item-title">
														{level.title}
													</div>
													<div className="level-item-description kpi-description">
														{level.description}
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="program-goals-section">
								<div
									className="container program-goals-container"
									style={{ maxWidth: '1100px' }}
								>
									<img src={ProgramGoalsImage} alt="program goals image" />
									<h3>How to set program goals</h3>
									<p>Determine your OKR calendar</p>
									<p>
										Most companies plan their OKRs quarterly, as
										follows:
									</p>

									<div className="goals-list">
										{goals.map((goal, index) => (
											<div className="goal-item" key={index}>
												<div className="goal-title">
													Q{index + 1}
												</div>
												<div className="goal-description kpi-description">
													{goal}
												</div>
											</div>
										))}
									</div>
								</div>
							</div>

							<div className="choices-section">
								<div
									className="container choices-container"
									style={{ maxWidth: '1000px' }}
								>
									<div className="choice">
										<div className="kpi-title">
											There is three ways to choose from
										</div>

										<div>
											<span className="choice-index">01</span>
											<span className="choice-description">
												Keep it simple
											</span>
											<div className="choice-description">
												When starting a new OKRs program,
												especially in a smaller company,
												consider keeping things simple. To
												start, try to limit the number of
												Objectives to 3 and ensure that they
												are only the most
											</div>
										</div>
									</div>

									<div className="choice">
										<span className="choice-index">02</span>
										<span className="choice-description">
											Top-down management
										</span>
										<div className="choice-description">
											You can adopt the top-down method by
											rolling out this program to leadership
											first, then to your people managers, and
											finally to individual team members. The
											benefit of this method is that employees
											see that senior leadership is fully behind
											the program, serving as a model example for
											the program’s importance.
										</div>
									</div>

									<div className="choice">
										<span className="choice-index">03</span>
										<span className="choice-description">
											Department pilot
										</span>
										<div className="choice-description">
											You can adopt the top-down method by
											rolling out this program to leadership
											first, then to your people managers, and
											finally to individual team members. The
											benefit of this method is that employees
											see that senior leadership is fully behind
											the program, serving as a model example for
											the program’s importance.
										</div>
									</div>
								</div>

								<div className="container" style={{ maxWidth: '1000px' }}>
									<h3 className="kpi-title">Note</h3>

									<p className="kpi-description">
										Find what works best for Your organization. Your
										strategy will grow and mature just as your OKR
										process does. Additionally, it's important to note
										that in each case, it will take at least 1-2
										quarters to get into a good rhythm and make some
										necessary adjustments.
									</p>
								</div>
							</div>

							<div className="final-section">
								<div className="container" style={{ maxWidth: '700px' }}>
									<h3 className="kpi-title">
										With Icloudready OKRs Software you will
									</h3>

									<p className="kpi-description">
										Get your team working in the right direction.
										Connect OKRs to strategy, KPIs and growth to
										develop a winning approach that delivers results
										that matter to the organization
									</p>

									<Link
										{...linkProps}
										className="kpi-link kpi-gradient-link"
									>
										Start for free
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-section">
						<MDXRenderer>{data.Footer.nodes[0].body}</MDXRenderer>
					</div>
				</div>
			</Layout>
		</Fragment>
	)
}

export const pageQuery = graphql`
	query KpiQuery {
		Footer: allMdx(filter: { frontmatter: { title: { eq: "Footer" } } }) {
			nodes {
				body
			}
		}
	}
`

export default IndexPage
